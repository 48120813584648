<script setup lang="ts">
import { computed, toValue } from 'vue';
import { WsSelect, WsSelectOption, WsButton } from '@mfl/common-components';

import strings from '../../../../settings-strings';
import { getWebAppUrl } from '../../../../settings-utils';
import { navigateTo } from '@mfl/framework';
import { IntegrationType } from '@msl/settings-gateway-sdk';

const model = defineModel<IntegrationType>({ required: true });

const props = defineProps<{
  isIntegrated: boolean;
  domainId: string | number;
  marketplaceLink?: string;
}>();

const emit = defineEmits(['remove-domain-integration']);

const marketplaceLink = computed<string>(() => {
  return props.marketplaceLink || '';
});

const isIntegrationGoogle = computed(
  () => toValue(model) === IntegrationType.GOOGLE
);

const isIntegrationMicrosoft = computed(
  () => toValue(model) === IntegrationType.MICROSOFT
);

const redirectToEmployee = () => {
  window.location.assign(`${getWebAppUrl()}?open-integration`);
};

const handleManageIntegrationClick = () => {
  navigateTo(toValue(marketplaceLink), true);
};

const emailServiceOptions = [
  IntegrationType.GOOGLE,
  IntegrationType.MICROSOFT,
  IntegrationType.OTHER,
];

const optionsLabels = {
  [IntegrationType.GOOGLE]: strings.googleWorkspace,
  [IntegrationType.MICROSOFT]: strings.microsoft,
  [IntegrationType.OTHER]: strings.other,
  [IntegrationType.UNSPECIFIED]: strings.select,
};
</script>

<template>
  <div class="email-service-control">
    <ws-select
      v-model="model"
      :option-label="(v) => optionsLabels[v]"
      :option-key="(v) => v"
      :disabled="props.isIntegrated"
      aid="EMAIL_SERVISE_DD"
    >
      <ws-select-option v-for="v in emailServiceOptions" :key="v" :value="v">
        {{ optionsLabels[v] }}
      </ws-select-option>
    </ws-select>
    <template v-if="!props.isIntegrated">
      <ws-button
        v-if="isIntegrationGoogle"
        aid="EMAIL_SERVICE_CONNECT_GOOGLE_BTN"
        :label="strings.EmailServiceConnectGoogleButton"
        color="primary"
        @click="redirectToEmployee()"
      />
      <ws-button
        v-else-if="isIntegrationMicrosoft"
        :label="strings.EmailServiceConnectmicrosoftButton"
        color="primary"
        aid="EMAIL_SERVISE_CONNECT_O365_BTN"
        @click="redirectToEmployee()"
      />
      <p
        v-if="isIntegrationGoogle || isIntegrationMicrosoft"
        class="connect-btn-description"
      >
        {{ strings.EmailServiceConnectButtonDescription }}
      </p>
    </template>
    <div v-else class="manage-integration-wrapper">
      <ws-button
        aid="EMAIL_SERVISE_MANAGE_BTN"
        class="manage-btn"
        :label="strings.manage"
        variant="text"
        @click="handleManageIntegrationClick"
      />
      <ws-button
        aid="EMAIL_SERVISE_REMOVE_INTEGRATION_BTN"
        class="remove-integration-btn"
        color="gray-400"
        :label="strings.remove"
        variant="text"
        @click="emit('remove-domain-integration')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.email-service-control {
  min-width: 200px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  .connect-btn-description {
    color: #828282;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .manage-integration-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 14px;

    .manage-btn {
      cursor: pointer;
      color: #1e90fa;
      font-family: Mulish;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      text-decoration: none;
    }

    .remove-integration-btn {
      cursor: pointer;
      color: #828282;
      font-family: Mulish;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
    }
  }
}
</style>
