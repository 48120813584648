<script setup lang="ts">
import { WsTooltip } from '@mfl/common-components';

const props = withDefaults(
  defineProps<{
    tooltip: string;
    tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
    class?: string;
    aid: string;
  }>(),
  {
    class: 'fa-regular fa-circle-info tooltip-icon-trigger',
    tooltipPosition: 'top',
  }
);
</script>
<template>
  <i :class="props.class">
    <ws-tooltip
      :aid="props.aid"
      :maxWidth="'500px'"
      :position="tooltipPosition"
    >
      {{ props.tooltip }}
    </ws-tooltip>
  </i>
</template>
<style lang="scss" scoped>
.tooltip-icon-trigger {
  //tooltip trigger icon size
  font-size: 16px;
  color: '#828282';
}
</style>
