<script setup lang="ts">
import EditDomainName from './controls/edit-domain-name.vue';
import EmailServiceSelect from './controls/email-service-select.vue';
import TimezoneSelect from './controls/timezone-select.vue';

import strings from '../../../settings-strings';

import {
  DomainBasicInfoModel,
  GeneralDomainInfo,
} from '../../../settings-types';

import WsCard from '../../../components/card/ws-card.vue';
import WsCardSection from '../../../components/card/ws-card-section.vue';
import WsCardSectionSeparator from '../../../components/card/ws-card-section-separator.vue';
import { DeepReadonly } from 'vue';
import { IntegrationType } from '@msl/settings-gateway-sdk';

const props = defineProps<{
  modelValue: DomainBasicInfoModel;
  domainGeneralInfo: DeepReadonly<GeneralDomainInfo>;
  errors: Record<string, string | undefined>;
}>();

const emit = defineEmits(['remove-domain-integration', 'update:modelValue']);

const handleUpdateDomainName = (name: string) => {
  emit('update:modelValue', {
    ...props.modelValue,
    domain: name,
  });
};

const handleUpdateDomainIntegrationType = (
  integrationType: IntegrationType
) => {
  emit('update:modelValue', {
    ...props.modelValue,
    integrationType,
  });
};

const handleUpdateDomainTimezone = (tz: string) => {
  emit('update:modelValue', {
    ...props.modelValue,
    tz,
  });
};
</script>

<template>
  <ws-card>
    <ws-card-section>
      <template #title>{{ strings.domainUrl }}</template>
      <template #desc>{{ strings.domainGeneralDescr }}</template>
      <template #control>
        <edit-domain-name
          :model-value="modelValue.domain"
          :is-integrated="props.domainGeneralInfo.isIntegrated"
          :error="props.errors['domain']"
          @update:model-value="handleUpdateDomainName"
        />
      </template>
    </ws-card-section>
    <ws-card-section-separator />
    <ws-card-section>
      <template #title>{{ strings.emailService }}</template>
      <template #desc>{{ strings.domainEmailServiceDescr }}</template>
      <template #control>
        <email-service-select
          :model-value="modelValue.integrationType"
          :is-integrated="props.domainGeneralInfo.isIntegrated"
          :marketplace-link="props.domainGeneralInfo.marketplaceLink"
          :domain-id="props.domainGeneralInfo.id"
          @update:model-value="handleUpdateDomainIntegrationType"
          @remove-domain-integration="emit('remove-domain-integration')"
        />
      </template>
    </ws-card-section>
    <ws-card-section-separator />
    <ws-card-section>
      <template #title>{{ strings.timeZone }}</template>
      <template #desc>{{ strings.domainTimeZoneDescr }}</template>
      <template #control>
        <timezone-select
          :model-value="modelValue.tz"
          @update:model-value="handleUpdateDomainTimezone"
        />
      </template>
    </ws-card-section>
  </ws-card>
</template>
